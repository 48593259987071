import React, { useState, useEffect } from "react";
import logo from "../Assets/BrandLogo/logo.png";
import { Menu, X } from "react-feather";
import { useNavigate } from "react-router-dom";
import { usePage } from "../Context/PageContext";
import ShinyButton from "./magicui/ShinyButton";

function Navbar() {
  const { currentPage, setCurrentPage } = usePage();
  const [menuOpen, setMenuOpen] = useState(false);
  const [hasShadow, setHasShadow] = useState(false); // State to control shadow on scroll
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navigateUser = (route) => {
    setCurrentPage(route);
    navigate(route);
    setMenuOpen(false);
  };

  // Add scroll event listener to apply shadow when user scrolls
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Desktop Navbar */}
      <div className="w-full relative z-50 lg:flex hidden justify-center bg-white">
        <div className="bg-white border-b-[1px] border-b-gray-300 w-full top-[1.5rem] flex items-center h-[4.7rem] px-6 justify-between max-w-[100vw] mx-auto hover:shadow-lg transition-all duration-300 ease-in-out">
          {/* Logo */}
          <div className="flex flex-col items-start mb-6 md:mb-0">
            <a href="/">
              <img src={logo} alt="Logo" className="w-[9rem] mb-0" />
            </a>
          </div>

          {/* Navigation Links */}
          <div className="flex items-center justify-center gap-x-10 text-[15px] font-medium text-[#3B3B3B]">
            {[
              { label: "Home", path: "/" },
              { label: "About", path: "/about" },
              { label: "Blogs", path: "/blogs" },
              { label: "Contact Us", path: "/contact" },
              { label: "Trust & Safety", path: "/security" },
            ].map((item) => (
              <span
                key={item.path}
                onClick={() => navigateUser(item.path)}
                className={`cursor-pointer ${
                  currentPage === item.path
                    ? "text-[#1A73E8]"
                    : "hover:text-[#1A73E8]"
                } transition-colors duration-200 ease-in-out`}
              >
                {item.label}
              </span>
            ))}
          </div>

          <ShinyButton>
            <a
              href="https://app.labops.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Sign up For Free
            </a>
          </ShinyButton>

          {/* Sign Up Button */}
        </div>
      </div>

      {/* Mobile Navbar */}
      <div
        className={`lg:hidden fixed w-full z-50 flex justify-between px-4 items-center h-[5rem] transition-shadow duration-300 ${
          hasShadow && !menuOpen ? "shadow-md " : ""
        } ${menuOpen ? "bg-white" : "bg-white "} `}
      >
        <img src={logo} className="w-[7rem]" alt="Labops" />
        <div onClick={toggleMenu} className="cursor-pointer">
          {menuOpen ? <X size={30} /> : <Menu size={30} />}
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`lg:hidden fixed top-[5rem] left-0 w-full bg-white  z-40 transform ${
          menuOpen ? "translate-y-0" : "-translate-y-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex flex-col items-center py-8 space-y-6 text-lg font-medium text-[#3B3B3B] max-w-[81rem] mx-auto">
          <span
            onClick={() => navigateUser("/")}
            className={`${
              currentPage === "/" ? "text-[#1A73E8]" : "hover:text-[#1A73E8]"
            } cursor-pointer transition-colors duration-200 ease-in-out`}
          >
            Home
          </span>
          <div className="w-[80%] h-[1px] bg-gray-200"></div>

          <span
            onClick={() => navigateUser("/about")}
            className={`${
              currentPage === "/about"
                ? "text-[#1A73E8]"
                : "hover:text-[#1A73E8]"
            } cursor-pointer transition-colors duration-200 ease-in-out`}
          >
            About
          </span>
          <div className="w-[80%] h-[1px] bg-gray-200"></div>

          <span
            onClick={() => navigateUser("/blogs")}
            className={`${
              currentPage === "/blogs"
                ? "text-[#1A73E8]"
                : "hover:text-[#1A73E8]"
            } cursor-pointer transition-colors duration-200 ease-in-out`}
          >
            Blogs
          </span>
          <div className="w-[80%] h-[1px] bg-gray-200"></div>

          <span
            onClick={() => navigateUser("/contact")}
            className={`${
              currentPage === "/contact"
                ? "text-[#1A73E8]"
                : "hover:text-[#1A73E8]"
            } cursor-pointer transition-colors duration-200 ease-in-out`}
          >
            Contact Us
          </span>
          <div className="w-[80%] h-[1px] bg-gray-200"></div>

          <span
            onClick={() => navigateUser("/security")}
            className={`${
              currentPage === "/security"
                ? "text-[#1A73E8]"
                : "hover:text-[#1A73E8]"
            } cursor-pointer transition-colors duration-200 ease-in-out`}
          >
            Trust & Safety
          </span>
        
          <a
            href="https://app.labops.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-gradient-to-r from-[#4C8DF5] to-[#1A73E8] text-lg font-semibold w-[12rem] h-[3.5rem] rounded-full text-white shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out mt-6">
              Sign Up for Free
            </button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Navbar;
