import React, { useState } from "react";
import { Facebook, Twitter, Youtube, Instagram, Linkedin } from "react-feather";
import logo from "../Assets/BrandLogo/whitelogo.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";

function Footer() {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const subEmail = async () => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is provided
    if (!email?.length) {
      toast.error("Email is required");
      return;
    }

    // Validate the email format
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/management/subscribe-email/`,
        {
          email: email,
        }
      );
      setLoader(false);
      toast.success("Subscribed successfully!");
      setEmail(""); // Clear the email input
    } catch (error) {
      setLoader(false);
      toast.error("An error occurred. Please try again.");
    }
  };
  return (
    <footer className="bg-blue-900 text-white py-12">
      <div className="container mx-auto px-4 xs:px-6 sml:px-8 lg:px-24">
        {/* Top Section */}
        <div className="flex flex-col items-start md:flex-row md:items-center justify-between mb-8">
          {/* Logo and Tagline */}
          <div className="flex flex-col items-start mb-6 md:mb-0">
            <a href="/">
              <img src={logo} alt="Logo" className="w-[10rem] mb-3" />
            </a>
          </div>

          {/* Social Media Icons */}
          <div className="flex space-x-6 justify-start md:justify-center w-full md:w-auto">
            {/* <a
              href="#"
              className="bg-white p-2 rounded-full text-blue-900 hover:bg-blue-500 hover:text-white shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
              aria-label="Facebook"
            >
              <Facebook className="h-6 w-6" />
            </a> */}
            <a
              href="https://www.linkedin.com/company/labopsmeditechnology/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white p-2 rounded-full text-blue-900 hover:bg-blue-500 hover:text-white shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
              aria-label="LinkedIn"
            >
              <Linkedin className="h-6 w-6" />
            </a>

            {/* <a
              href="#"
              className="bg-white p-2 rounded-full text-blue-900 hover:bg-blue-500 hover:text-white shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
              aria-label="Twitter"
            >
              <Twitter className="h-6 w-6" />
            </a> */}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/@Labops2023"
              className="bg-white p-2 rounded-full text-blue-900 hover:bg-blue-500 hover:text-white shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
              aria-label="YouTube"
            >
              <Youtube className="h-6 w-6" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/labops.in?igsh=MXJpdzJpNnQ5azZuag=="
              className="bg-white p-2 rounded-full text-blue-900 hover:bg-blue-500 hover:text-white shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
              aria-label="Instagram"
            >
              <Instagram className="h-6 w-6" />
            </a>
          </div>
        </div>

        {/* Links Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 text-left">
          {/* Product Links */}
          <div>
            <h3 className="font-semibold text-lg mb-4 text-white">Product</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="/"
                  className="text-gray-300 hover:text-white transition duration-200"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  className="text-gray-300 hover:text-white transition duration-200"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/blogs"
                  className="text-gray-300 hover:text-white transition duration-200"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="text-gray-300 hover:text-white transition duration-200"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          {/* Legal Details */}
          <div>
            <h3 className="font-semibold text-lg mb-4 text-white">Legal</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="/privacy-policy"
                  className="text-gray-300 hover:text-white transition duration-200"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/terms-conditions"
                  className="text-gray-300 hover:text-white transition duration-200"
                >
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="font-semibold text-lg mb-4 text-white">Contact</h3>
            <p className="text-gray-300">Lake town, Kolkata, India, 700089</p>
            <p className="text-gray-300 mt-2"> +91 9002841677 </p>
            <p className="text-gray-300 mt-2">contact@labops.in</p>
          </div>

          {/* Extra Section */}
          <div>
            <h3 className="font-semibold text-lg mb-4 text-white">
              Stay Updated
            </h3>
            <p className="text-gray-300 mb-4">
              Sign up for our newsletter to receive the latest updates and
              special offers.
            </p>
            <div className="flex flex-col space-y-2">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
                className="px-4 py-2 rounded bg-blue-800 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={() => subEmail()}
                type="submit"
                className="bg-blue-600 flex justify-center items-center h-[2.5rem] hover:bg-blue-500 transition duration-300 text-white py-2 rounded"
              >
                {loader ? (
                  <ThreeDots
                    visible={true}
                    height="50"
                    width="50"
                    color="white"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                ) : (
                  "  Subscribe"
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Footer bottom text */}
        <div className="mt-8 border-t border-gray-700 pt-4 text-left sm:text-center">
          <p className="text-gray-400 text-sm">
            © 2024 Labops. All Rights Reserved. Designed by Labops
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
