import React, { useState } from "react";
import img1 from "../Assets/Subscription/1.png";
import img2 from "../Assets/Subscription/2.png";
import img3 from "../Assets/Subscription/3.png";
import img7 from "../Assets/Home/Rectangle 158.png";
import { ChevronDown, ChevronUp } from "react-feather";
const PricingPlanComp = () => {
  const [showMoreStarter, setShowMoreStarter] = useState(false);
  const [showMoreAdvanced, setShowMoreAdvanced] = useState(false);
  const [showMorePremium, setShowMorePrmuim] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [expanded, setExpanded] = useState({});

  const toggleReadMore = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const accordionData = [
    {
      question: "Is Labops suitable for both pathology and diagnostic labs?",
      answer:
        "Yes, Labops is designed to cater to the needs of both pathology and diagnostic labs, making it a versatile solution for various laboratory settings.",
    },
    {
      question: "How secure is Labops for health data?",
      answer:
        "Labops prioritizes your privacy and security. With end-to-end encryption, HIPAA compliance, and regular audits, your health data is safeguarded with the highest standards.",
    },
    {
      question: "Can I access Labops from mobile devices?",
      answer:
        "Yes, Labops is accessible from both desktop and mobile devices, ensuring you can manage your lab anytime, anywhere.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
  ];
  return (
    <>
      <div className="bg-gray-50 pt-[8rem] md:pt-[4rem] px-3">
        {/* Header */}
        <h1 className="text-center text-2xl sm:text-4xl font-semibold mb-[2rem] md:mb-[8rem]">
          Grow with the Right Tools – Pick Your Plan Today
        </h1>

        {/* Pricing Plans */}
        <div className="max-w-[81rem] mx-auto grid grid-cols-1 sm:grid-cols-3 gap-6">
          {/* Starter Plan */}
          <div
            className={`border-2 border-gray-400 ${
              showMore ? "h-[46rem]" : "h-[39rem]"
            } rounded-[1rem] p-6 text-left bg-white shadow-lg`}
          >
            <div className="mb-0">
              <img src={img1} className="w-[6rem] h-[6rem] " alt="" />
            </div>
            <h2 className="text-xl font-semibold">Starter</h2>
            <p className="text-gray-500 mb-1">
              For labs that are just starting out on their journey today.
            </p>

            <p className="text-4xl font-bold">
              $0<span className="text-lg font-normal"> / month</span>
            </p>
            <div className="h-[1.5px] w-full bg-gray-300 mb-0 mt-4"></div>
            <button className="mt-5  border border-[#6a72fa] text-[#6a72fa] text-[1.3rem] font-bold rounded-xl  h-[3.4rem] px-4 w-full ">
              Sign Up
            </button>
            <ul className="mt-6 space-y-4 text-left">
              <li className="flex items-center">
                <svg
                  className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={3}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Up to 20 Patients daily
              </li>
              <li className="flex items-center">
                <svg
                  className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={3}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                1 login user
              </li>
              <li className="flex items-center">
                <svg
                  className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={3}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                1 branch management
              </li>
              <li className="flex items-center">
                <svg
                  className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={3}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Multi-Device Access
              </li>
              <li className="flex items-center">
                <svg
                  className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={3}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                Doctor Management
              </li>
              <div className="flex items-center gap-2">
                <li className="flex items-center">
                  <svg
                    className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  Referral Management
                </li>
                {!showMore && (
                  <span
                    onClick={() => setShowMore(true)}
                    className="text-[#014ee2] cursor-pointer text-[0.7rem]"
                  >
                    See more
                  </span>
                )}
              </div>
              {showMore && (
                <>
                  <li className="flex items-center text-gray-400">
                    <svg
                      className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-gray-400 mr-2 border-[2px] border-gray-400 rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={5}
                    >
                      {/* Make circle transparent */}
                      <line
                        x1="2"
                        y1="12"
                        x2="33"
                        y2="12"
                        strokeLinecap="round"
                        stroke="currentColor"
                      />
                    </svg>
                    Dedicated Support
                  </li>
                  <li className="flex items-center text-gray-400">
                    <svg
                      className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-gray-400 mr-2 border-[2px] border-gray-400 rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={5}
                    >
                      {/* Make circle transparent */}
                      <line
                        x1="2"
                        y1="12"
                        x2="33"
                        y2="12"
                        strokeLinecap="round"
                        stroke="currentColor"
                      />
                    </svg>
                    Enhance Security
                  </li>
                  <div className="flex items-center gap-2">
                    <li className="flex items-center text-gray-400">
                      <svg
                        className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-gray-400 mr-2 border-[2px] border-gray-400 rounded-full"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={5}
                      >
                        {/* Make circle transparent */}
                        <line
                          x1="2"
                          y1="12"
                          x2="33"
                          y2="12"
                          strokeLinecap="round"
                          stroke="currentColor"
                        />
                      </svg>
                      Referral Management
                    </li>
                    <span
                      onClick={() => setShowMore(false)}
                      className="text-[#014ee2] cursor-pointer text-[0.7rem]"
                    >
                      See less
                    </span>
                  </div>
                </>
              )}
            </ul>
          </div>

          {/* Advanced Plan */}

          <div
            className={`relative p-[2px] rounded-[1rem] mb-[4rem] ${
              showMore ? "h-[46rem]" : "h-[39rem]"
            } bg-gradient-to-br from-[#5de0e6] to-[#004aad]`}
          >
            <div className="bg-white rounded-[0.9rem] p-6 text-left h-full shadow-lg">
              <div className="mb-0">
                <img src={img2} className="w-[6rem] h-[6rem] " alt="" />
              </div>
              <h2 className="text-xl font-semibold">Advanced</h2>
              <p className="text-gray-500 mb-1">
                For labs that are in the intermediate stage of their journey.
              </p>
              <p className="text-4xl font-bold">
                $9<span className="text-lg font-normal"> / month</span>
              </p>
              <div className="h-[1.5px] w-full bg-gray-300 mb-0 mt-4"></div>
              <button className="mt-5  border border-[#6a72fa] text-[#6a72fa] text-[1.3rem] font-bold rounded-xl  h-[3.4rem] px-4 w-full ">
                Choose Plan
              </button>
              <ul className="mt-6 space-y-4 text-left ">
                <li className="flex items-center text-[#014ee2]">
                  <svg
                    className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  All starter benefits
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  Up to 40 Patients daily
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  4 login user
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  2 branch management
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  Excel Export (Doctor & Patients)
                </li>
                <div className="flex items-center gap-2">
                  <li className="flex items-center">
                    <svg
                      className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    WhatsApp Sharing
                  </li>
                  {!showMore && (
                    <span
                      onClick={() => setShowMore(true)}
                      className="text-[#014ee2] cursor-pointer text-[0.7rem]"
                    >
                      See more
                    </span>
                  )}
                </div>
                {showMore && (
                  <>
                    <li className="flex items-center">
                      <svg
                        className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#7ed957] rounded-full"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={3}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      Dedicated Support
                    </li>
                    <li className="flex items-center ">
                      <svg
                        className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#7ed957] rounded-full"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={3}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      Enhance Security
                    </li>
                    <div className="flex items-center gap-2">
                      <li className="flex items-center">
                        <svg
                          className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#7ed957] rounded-full"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        Referral Management
                      </li>
                      <span
                        onClick={() => setShowMore(false)}
                        className="text-[#014ee2] cursor-pointer text-[0.7rem]"
                      >
                        See less
                      </span>
                    </div>
                  </>
                )}
              </ul>
            </div>
          </div>

          {/* Premium Plan */}

          <div className="relative">
            <div
              className={`relative p-[2px] top-[-3.8rem]  ${
                showMore ? "h-[49.8rem]" : "h-[39rem]"
              } bg-gradient-to-br from-[#5de0e6] to-[#004aad] pt-[3.5rem] h-[43rem] rounded-bl-[1rem] rounded-[1rem] px-[0.4rem] py-[0.4rem]`}
            >
              {/* <div className="h-[4rem] absolute w-[103%]   bg-gradient-to-br from-[#5de0e6] to-[#004aad] top-[-4rem] left-[-6px]"></div> */}
              <div className="absolute text-2xl w-[95%] font-bold text-white text-center top-[0.7rem] ">
                {" "}
                The Ultimate
              </div>
              <div className="bg-white rounded-[0.7rem] p-6 text-left h-full shadow-lg ">
                <div className="mb-0">
                  <img src={img3} className="w-[6rem] h-[6rem] " alt="" />
                </div>
                <h2 className="text-xl font-semibold">Premium</h2>
                <p className="text-gray-500 mb-2">
                  For large labs with well-established operations and extensive
                  experience in place.
                </p>
                <p className="text-4xl font-bold">
                  $19<span className="text-lg font-normal"> / month</span>
                </p>
                <div className="h-[1.5px] w-full bg-gray-300 mb-0 mt-4"></div>
                <button className="mt-5  bg-gradient-to-br from-[#5de0e6] to-[#004aad] text-white text-[1.3rem] font-bold rounded-xl h-[3.4rem] px-4 w-full ">
                  Choose Plan
                </button>
                <ul className={`mt-6 space-y-4 text-left `}>
                  <li className="flex items-center text-[#014ee2]">
                    <svg
                      className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    All advanced benefits
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    Unlimited Patients entry
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    Dedicated customer support
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    2 branch management
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    All data Excel Export
                  </li>
                  <div className="flex items-center gap-2">
                    <li className="flex items-center">
                      <svg
                        className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#6a72fa] rounded-full"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={3}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      All data Excel Import
                    </li>
                    {!showMoreAdvanced && (
                      <span
                        onClick={() => setShowMore(true)}
                        className="text-[#014ee2] cursor-pointer text-[0.7rem]"
                      >
                        See more
                      </span>
                    )}
                  </div>
                  {showMore && (
                    <>
                      <li className="flex items-center text-black">
                        <svg
                          className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#7ed957] rounded-full"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        Dedicated Support
                      </li>
                      <li className="flex items-center ">
                        <svg
                          className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#7ed957] rounded-full"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        Enhance Security
                      </li>
                      <div className="flex items-center gap-2">
                        <li className="flex items-center ">
                          <svg
                            className="w-[1.3rem] h-[1.3rem] p-[0.2rem] text-white mr-2 bg-[#7ed957] rounded-full"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={3}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          Referral Management
                        </li>
                        <span
                          onClick={() => setShowMore(false)}
                          className="text-[#014ee2] cursor-pointer text-[0.7rem]"
                        >
                          See less
                        </span>
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col max-w-[81rem] md:flex-row mx-auto justify-between items-center md:items-start py-8">
          {/* Left Section */}
          <div className="text-gray-800 mb-8 md:mb-0 md:w-1/2">
            <h2 className="md:text-4xl text-center md:text-left text-2xl font-bold mb-4 md:eading-[3rem]">
              24/7 assistance to ensure your business success
            </h2>
          </div>

          {/* Right Section */}
          <div className="md:w-1/2">
            {/* First Point */}
            <div className="flex items-start mb-6">
              <svg
                className="text-green-500 w-[2.5rem] h-[2.5rem] md:h-6 md:w-6 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M20.83 6.58a.75.75 0 010 1.06l-9.07 9.07a.75.75 0 01-1.06 0l-4.07-4.07a.75.75 0 111.06-1.06l3.54 3.54 8.54-8.54a.75.75 0 011.06 0z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="text-gray-700 text-[1.1rem] font-semibold">
                Expert help is at your fingertips with our{" "}
                <span className="text-blue-500">
                  live chat and email support
                </span>
                .
              </p>
            </div>

            {/* Second Point */}
            <div className="flex items-start mb-6">
              <svg
                className="text-green-500 w-[2.5rem] h-[2.5rem] md:h-6 md:w-6 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M20.83 6.58a.75.75 0 010 1.06l-9.07 9.07a.75.75 0 01-1.06 0l-4.07-4.07a.75.75 0 111.06-1.06l3.54 3.54 8.54-8.54a.75.75 0 011.06 0z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="text-gray-700 text-[1.1rem] font-semibold">
                No more waiting — we commit to resolving issues{" "}
                <span className="text-blue-500">within a few minutes</span>.
              </p>
            </div>

            {/* Third Point */}
            <div className="flex items-start">
              <svg
                className="text-green-500 w-[2.5rem] h-[2.5rem] md:h-6 md:w-6 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M20.83 6.58a.75.75 0 010 1.06l-9.07 9.07a.75.75 0 01-1.06 0l-4.07-4.07a.75.75 0 111.06-1.06l3.54 3.54 8.54-8.54a.75.75 0 011.06 0z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="text-gray-700 text-[1.1rem] font-semibold">
                Effective communication is our priority — our agents{" "}
                <span className="text-blue-500">speak local languages</span>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full flex flex-col items-center bg-[#F2F4EF] py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full flex max-w-[81rem] flex-col lg:flex-row gap-y-5 gap-x-10 items-start">
          {/* Left Text Section */}
          <div className="lg:flex-1 w-full">
            <div className="w-full flex flex-col gap-y-3">
              <span className="text-[#3B3B3B] text-3xl md:text-4xl font-bold">
                Frequently Asked Questions
              </span>
              <span className="text-[#6B6B6B] text-lg">
                Get answers to the most commonly asked questions about Labops.
              </span>
            </div>

            {/* Accordion */}
            <div className="mt-8 flex flex-col gap-y-2">
              {accordionData.map((item, index) => (
                <div
                  key={index}
                  className={`border-b-2 border-[#E0E0E0] rounded-xl py-4 transition-all duration-500 ease-in-out ${
                    activeIndex === index
                      ? "bg-white shadow-lg rounded-md"
                      : "bg-white"
                  }`}
                >
                  <div
                    className="flex justify-between items-center px-5 py-2 cursor-pointer transition-transform transform"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="text-[#3B3B3B] text-lg font-medium w-[90%]">
                      {item.question}
                    </span>
                    <span className="text-[#6B6B6B] bg-[#D9D9D9] flex justify-center items-center h-[1.6rem] w-[1.6rem] rounded-lg text-xl transition-transform duration-500 ease-in-out">
                      {activeIndex === index ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </span>
                  </div>

                  {/* Accordion content with smoother transition */}
                  <div
                    className={`overflow-hidden px-5 transition-all duration-700 ease-in-out ${
                      activeIndex === index
                        ? "max-h-[1000px] opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                    style={{
                      transition:
                        "max-height 0.7s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.7s ease-in-out",
                    }}
                  >
                    <div className="mt-4 text-[#6B6B6B] text-base">
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Image Section */}
          <div className="md:flex-1 w-full lg:max-w-[35%]">
            <img
              src={img7}
              className="rounded-lg w-full object-cover shadow-md"
              alt="FAQ Image"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPlanComp;
