import React, { useEffect } from "react";
import ContactComp from "../Components/ContactComp";
import { usePage } from "../Context/PageContext";

function Contact() {
  const { currentPage, setCurrentPage } = usePage();

  useEffect(() => {
    setCurrentPage("/contact");
  }, []);
  return (
    <>
      <ContactComp />
    </>
  );
}

export default Contact;
