import React, { createContext, useContext, useState } from "react";

// Create context
const PageContext = createContext();

// Custom hook to use the Page context
export const usePage = () => useContext(PageContext);

// Page Provider component
export const PageProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState("/");

  return (
    <PageContext.Provider value={{ currentPage, setCurrentPage }}>
      {children}
    </PageContext.Provider>
  );
};
