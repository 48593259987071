import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import HomeComp from "../Components/HomeComp";
import { usePage } from "../Context/PageContext";

function Home() {
  const { currentPage, setCurrentPage } = usePage();

  useEffect(() => {
    setCurrentPage("/");
  }, []);
  return (
    <>
      <HomeComp />
    </>
  );
}

export default Home;
