import React, { useState } from "react";
import { Check, Mail, MapPin, PhoneCall } from "react-feather";
import img7 from "../Assets/Home/Rectangle 158.png";
import img6 from "../Assets/Home/image.png";

import img9 from "../Assets/Home/009 1.png";
import {
  Activity,
  Award,
  ChevronDown,
  ChevronUp,
  Cpu,
  Lock,
} from "react-feather";
import ShinyButton from "./magicui/ShinyButton";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
function ContactComp() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [check, setCheck] = useState(false);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const sendData = async () => {
    // Initialize an array to hold messages about missing fields
    const missingFields = [];

    // Check for required fields and push missing ones to the array
    if (!firstName) missingFields.push("First Name");
    if (!lastName) missingFields.push("Last Name");
    if (!email) missingFields.push("Email");
    if (!phone) missingFields.push("Mobile");

    // If there are any missing fields, show an error toast for each one
    if (missingFields.length > 0) {
      missingFields.forEach((field) => {
        toast.error(`${field} is required.`); // Show individual error messages for each missing field
      });
      return; // Exit the function early
    }

    if (!check) {
      toast.error("Plaese agree to our privacy policy.");
      return;
    }

    try {
      setLoader(true);
      // Create the initial data object
      let data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        mobile: phone,
      };

      // Add message to data only if it has content
      if (message?.length > 0) {
        data.message = message;
      }

      // Send the data to the API
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/management/contact-us/`,
        data // Include the data in the request
      );

      // Show success message only after successful request
      toast.success("Message sent successfully!");
      setFirstName("");
      setLastName("");
      setEmail("");
      setMessage("");
      setPhone("");
      setCheck(false);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      // Show error message if the request fails
      toast.error("An error occurred. Please try again.");
      console.log(error);
    }
  };

  const accordionData = [
    {
      question: "Is Labops suitable for both pathology and diagnostic labs?",
      answer:
        "Yes, Labops is designed to cater to the needs of both pathology and diagnostic labs, making it a versatile solution for various laboratory settings.",
    },
    {
      question: "How secure is Labops for health data?",
      answer:
        "Labops prioritizes your privacy and security. With end-to-end encryption, HIPAA compliance, and regular audits, your health data is safeguarded with the highest standards.",
    },
    {
      question: "Can I access Labops from mobile devices?",
      answer:
        "Yes, Labops is accessible from both desktop and mobile devices, ensuring you can manage your lab anytime, anywhere.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
  ];
  return (
    <>
      <div className="max-w-[81rem] w-full lg:py-16 pt-[7rem] lg:pt-[4rem] mx-auto p-4">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-8 bg-white border border-gray-200 p-4 lg:p-6 rounded-lg shadow-md">
          {/* Form Section (Larger width) */}
          <div className="lg:col-span-3">
            <h2 className="text-3xl font-semibold mb-4">Get in touch</h2>
            <p className="text-gray-500 mb-8">
              Our friendly team would love to hear from you.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  id="first-name"
                  placeholder="First name"
                  className="mt-1 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  id="last-name"
                  placeholder="Last name"
                  className="mt-1 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="md:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  id="email"
                  placeholder="you@company.com"
                  className="mt-1 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="md:col-span-2">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone number
                </label>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  id="phone"
                  placeholder="+91 12345 67890"
                  className="mt-1 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="md:col-span-2">
                <label
                  htmlFor="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  placeholder="Your message"
                  rows="4"
                  className="mt-1 p-3 w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                ></textarea>
              </div>
              <div className="md:col-span-2">
                <div className="flex items-start relative">
                  <input
                    type="checkbox"
                    value={check}
                    onChange={(e) => setCheck(e.target.checked)}
                    id="privacy-policy"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="privacy-policy"
                    className="ml-2 text-sm text-gray-700"
                  >
                    You agree to our friendly{" "}
                    <a
                      href="/privacy-policy"
                      className="text-blue-600 underline"
                    >
                      privacy policy
                    </a>
                    .
                  </label>
                </div>
              </div>
              <div className="md:col-span-2">
                <button
                  onClick={() => sendData()}
                  type="submit"
                  className="w-full text-bold bg-[#1A73E8] h-[3rem] cursor-pointer flex items-center justify-center text-white p-3 rounded-md shadow-md hover:bg-blue-700 transition"
                >
                  {loader ? (
                    <ThreeDots
                      visible={true}
                      height="60"
                      width="60"
                      color="white"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    " Send message"
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* Contact Details Section (Smaller width) */}
          <div className="lg:col-span-2 space-y-6">
            <h3 className="text-[#3B3B3B] ">
              If you have any questions, inquiries, or would like to get in
              touch, please use the following contact information.
            </h3>

            <div className="flex items-center space-x-3">
              <div className="w-[2.5rem] h-[2.5rem] rounded-full bg-[#E8F1FD] flex justify-center items-center">
                <MapPin size={22} className="text-[#1A73E8]" />
              </div>

              <div className="flex-1">
                <p className="font-medium text-[1.1rem]">Location</p>
                <p className="text-[#6B6B6B] text-[0.9rem]">
                  <p className="text[#6B6B6B] text-[0.9rem]">
                    Lake town, Kolkata, India, 700089
                  </p>
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <div className="w-[2.5rem] h-[2.5rem] rounded-full bg-[#E8F1FD] flex justify-center items-center">
                <Mail size={22} className="text-[#1A73E8]" />
              </div>

              <div>
                <p className="font-medium text-[1.1rem]">Connect with us</p>

                <p className="text[#6B6B6B] text-[0.9rem]"> +91 9002841677</p>
                <p className="text[#6B6B6B] text-[0.9rem]">
                  {" "}
                  contact@labops.in
                </p>
              </div>
            </div>

            <div>
              <h4 className="font-medium mb-2">Find us on Map</h4>
              <div className="border border-gray-200 rounded-md overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509373!2d144.95373631531657!3d-37.81627927975198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5771fdd02a0a318!2sMelbourne%20Central%20Station!5e0!3m2!1sen!2sau!4v1613941636716!5m2!1sen!2sau"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full flex flex-col items-center bg-[#F2F4EF] py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full flex max-w-[81rem] flex-col lg:flex-row gap-y-5 gap-x-10 items-start">
          {/* Left Text Section */}
          <div className="lg:flex-1 w-full">
            <div className="w-full flex flex-col gap-y-3">
              <span className="text-[#3B3B3B] text-3xl md:text-4xl font-bold">
                Frequently Asked Questions
              </span>
              <span className="text-[#6B6B6B] text-lg">
                Get answers to the most commonly asked questions about Labops.
              </span>
            </div>

            {/* Accordion */}
            <div className="mt-8 flex flex-col gap-y-2">
              {accordionData.map((item, index) => (
                <div
                  key={index}
                  className={`border-b-2 border-[#E0E0E0] rounded-xl py-4 transition-all duration-500 ease-in-out ${
                    activeIndex === index
                      ? "bg-white shadow-lg rounded-md"
                      : "bg-white"
                  }`}
                >
                  <div
                    className="flex justify-between items-center px-5 py-2 cursor-pointer transition-transform transform"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="text-[#3B3B3B] text-lg font-medium w-[90%]">
                      {item.question}
                    </span>
                    <span className="text-[#6B6B6B] bg-[#D9D9D9] flex justify-center items-center h-[1.6rem] w-[1.6rem] rounded-lg text-xl transition-transform duration-500 ease-in-out">
                      {activeIndex === index ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </span>
                  </div>

                  {/* Accordion content with smoother transition */}
                  <div
                    className={`overflow-hidden px-5 transition-all duration-700 ease-in-out ${
                      activeIndex === index
                        ? "max-h-[1000px] opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                    style={{
                      transition:
                        "max-height 0.7s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.7s ease-in-out",
                    }}
                  >
                    <div className="mt-4 text-[#6B6B6B] text-base">
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Image Section */}
          <div className="md:flex-1 w-full lg:max-w-[35%]">
            <img
              src={img7}
              className="rounded-lg w-full object-cover shadow-md"
              alt="FAQ Image"
            />
          </div>
        </div>
      </div>

      <div className="w-full h-full relative flex flex-col items-center bg-white py-0 px-4 md:px-8 lg:px-6 text-[#3B3B3B]">
        <div className="w-full  max-w-[81rem]  flex flex-col gap-y-10 lg:flex-row items-center lg:gap-x-10">
          {/* Text Section */}
          <div className="flex-1 flex flex-col items-center lg:items-start gap-y-5 text-center lg:text-left">
            <h2 className="lg:text-6xl text-4xl leading-[3rem] font-bold lg:leading-[4.5rem]">
              We're Ready to Help, Contact Us Anytime!
            </h2>
            <p className="text-[#6B6B6B] text-base lg:text-lg">
              Got questions or need support? We're here to listen and assist you
              with whatever you need.
            </p>
            <div className="flex justify-start">
              <a href="/contact">
                <button className="bg-gradient-to-r from-[#4C8DF5] w-[16rem] mt-[1rem] to-[#1A73E8] text-[16px] font-semibold  max-w-[12rem] h-[3.5rem] rounded-lg flex items-center justify-center text-white shadow-md hover:shadow-lg transition-all duration-300 ease-in-out">
                  <span className="flex items-center">
                    <PhoneCall className="mr-2 w-5 h-5" />{" "}
                    {/* Phone icon with size */}
                    <span className="text-[16px] text-white">
                      Contact us now
                    </span>
                  </span>
                </button>
              </a>
            </div>
          </div>

          {/* Image Section */}
          <div className="flex-1 relative">
            <img src={img9} className="w-full object-cover" alt="Contact Us" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactComp;
