import React from "react";

const TermsConditionsComp = () => {
  return (
    <div className="min-h-[93vh] p-5">
      Terms of Service for Labops Meditechnology Last updated on: 24th June
      2024. THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT
      (HEREINAFTER “YOU” or “YOUR”) AND LABOPS MEDITECHNOLOGY (HEREINAFTER
      “LABOPS”) GOVERNING YOUR USE OF LABOPS PATHOLOGY SOFTWARE (HEREINAFTER
      “SERVICE” or “SERVICES”). Parts of this Agreement This Agreement consists
      of the following terms and conditions (hereinafter the “General Terms”)
      and terms and conditions specific to the use of individual Services
      (hereinafter the “Service Specific Terms”). In the event of a conflict
      between the General Terms and Service Specific Terms, the Service Specific
      Terms shall prevail. Acceptance of the Agreement You must be of legal age
      to enter into a binding agreement to accept the Agreement. If you do not
      agree to the General Terms, do not use any of our Services. If you agree
      to the General Terms and do not agree to any Service Specific Terms, do
      not use the corresponding Service. You can accept the Agreement by
      checking a checkbox or clicking on a button indicating your acceptance of
      the Agreement or by actually using the Services. Description of Service
      Labops is an innovative pathology software designed to streamline and
      enhance lab management. Our platform connects patients, doctors, and
      laboratories, providing a seamless experience for accessing and managing
      medical reports. With Labops, users can easily track patient details,
      monitor payments, and generate professional reports with QR codes. Labops
      aims to create a tech-friendly healthcare ecosystem, improving efficiency
      and making healthcare management simpler for everyone involved. The
      Services are offered free of charge for pathology labs. User Sign up
      Obligations You need to sign up for a user account by providing all
      required information in order to access or use the Services. You agree to:
      (i) provide true, accurate, current, and complete information about
      yourself as prompted by the sign-up process; and (ii) maintain and
      promptly update the information provided during sign-up to keep it true,
      accurate, current, and complete. If you provide any information that is
      untrue, inaccurate, outdated, or incomplete, or if Labops has reasonable
      grounds to suspect that such information is untrue, inaccurate, outdated,
      or incomplete, Labops may terminate your user account and refuse current
      or future use of any or all of the Services. Restrictions on Use In
      addition to all other terms and conditions of this Agreement, you shall
      not: 1. Transfer the Services or otherwise make them available to any
      third party. 2. Provide any service based on the Services without prior
      written permission. 3. Allow user licenses to be shared or used by more
      than one individual other than by way of reassigning the user license to a
      new user. 4. Attempt to disassemble, reverse engineer, or decompile the
      Services except as permitted under applicable law. 5. Use third-party
      links to sites without agreeing to their website terms & conditions. 6.
      Post links to third-party sites or use their logo, company name, etc.,
      without their prior written permission. 7. Attempt to gain unauthorized
      access to the Services or their related systems or networks. 8. Use the
      Services in any manner that could damage, disable, overburden, impair or
      harm any server, network, computer system, or resource of Labops. 9. Use
      the Services to send or store material containing software viruses, worms,
      or other harmful computer codes, files, scripts, or programs. 10. Use the
      Services in any manner that interferes with or disrupts the integrity,
      security, or performance of the Services, their components, and the data
      contained therein. 11. Host, display, upload, modify, publish, transmit,
      store, update, or share any information that belongs to another person or
      entity and to which you do not have any right, including personal or
      confidential information of any person or entity with respect to which you
      do not have consent or permission from such person or entity. 12. Violate
      any applicable local, state, national, or international law. 13. Use the
      Services for any form of competitive or benchmarking purposes. 14. Remove
      or obscure any proprietary or other notices contained in the Services. 15.
      Use the Services in any manner that threatens the unity, integrity,
      defense, security, or sovereignty of India, friendly relations of India
      with other countries, or public order, or causes incitement to the
      commission of any cognizable offense or prevents investigation of any
      offense or is insulting other countries. 16. Create a false identity to
      mislead any person as to the identity or origin of any communication. 17.
      Use the Services for transmitting information that is patently false and
      untrue, and is written or published in any form, with the intent to
      mislead or harass a person, entity, or agency for financial gain or to
      cause any injury to any person. 18. Use the Services in a manner that
      relates to or encourages any activity prohibited by law in India. Spamming
      and Illegal Activities You agree to be solely responsible for the contents
      of your transmissions through the Services. You agree not to use the
      Services for illegal purposes or for the transmission of material that is
      unlawful, defamatory, insulting, harassing, libelous, invasive of
      another's privacy (including bodily privacy), abusive, threatening,
      harmful, vulgar, pornographic, paedophilic, harmful to children, obscene,
      racially or ethnically objectionable, or is otherwise objectionable,
      offends religious sentiments, promotes racism, contains viruses or
      malicious code, or that which infringes or may infringe intellectual
      property or other rights of another. You agree not to use the Services for
      the transmission of "junk mail", "spam", "chain letters", “phishing” or
      unsolicited mass distribution of email. We reserve the right to terminate
      your access to the Services if there are reasonable grounds to believe
      that you have used the Services for any illegal or unauthorized activity.
      Third-Party Applications Labops Services integrate with many third-party
      applications (hereinafter "Third-Party Application(s)"). Access and use of
      the Third-Party Applications may require acceptance of terms of service
      and privacy policies applicable to such Third-Party Applications
      (hereinafter "Third-Party Terms"). You are responsible for reading and
      understanding the Third-Party Terms before accessing or using any
      Third-Party Application. You acknowledge and agree that Labops is not
      liable for any Third-Party Applications. While we will try to provide you
      with advance notice, whenever reasonably possible, you acknowledge and
      agree that Labops may, at any time and in our sole discretion, and without
      any notice to you, suspend, restrict or disable access to or remove from
      Labops Services, any Third-Party Application, without any liability to
      you, including without limitation for any loss of profits, revenue, data,
      goodwill or other intangible losses. Fees and Payments The Labops
      pathology software is available free of charge for pathology labs.
      Organization Accounts and Administrators When you sign up for an account
      for your organization, you may specify one or more administrators. The
      administrators will have the right to configure the Services based on your
      requirements and manage end-users in your organization account. If your
      organization account is created and configured on your behalf by a third
      party, it is likely that such third party has assumed the administrator
      role for your organization. Make sure that you enter into a suitable
      agreement with such third party specifying such party's roles and
      restrictions as an administrator of your organization account. You are
      responsible for (i) ensuring confidentiality of your organization account
      password, (ii) appointing competent individuals as administrators for
      managing your organization account, and (iii) ensuring that all activities
      that occur in connection with your organization account comply with this
      Agreement. You understand that Labops is not responsible for account
      administration and internal management of the Services for you. You are
      responsible for taking necessary steps for ensuring that your organization
      does not lose control of the administrator accounts. You may specify a
      process to be followed for recovering control in the event of such loss of
      control of the administrator accounts by sending an email to
      contact@labops.in, provided that the process is acceptable to Labops. In
      the absence of any specified administrator account recovery process,
      Labops may provide control of an administrator account to an individual
      providing proof satisfactory to Labops demonstrating authorization to act
      on behalf of the organization. You agree not to hold Labops liable for the
      consequences of any action taken by Labops in good faith in this regard.
      Personal Information and Privacy Personal information you provide to
      Labops through the Service is governed by Labops Privacy Policy. Your
      election to use the Service indicates your acceptance of the terms of the
      Labops Privacy Policy. You are responsible for maintaining the
      confidentiality of your username, password, and other sensitive
      information. You are responsible for all activities that occur in your
      user account and you agree to inform us immediately of any unauthorized
      use of your user account by email to contact@labops.in or by calling us at
      90028 41677. We are not responsible for any loss or damage to you or to
      any third party incurred as a result of any unauthorized access and/or use
      of your user account, or otherwise. Communications from Labops The Service
      may include certain communications from Labops, such as service
      announcements, administrative messages, and newsletters. You understand
      that these communications shall be considered part of using the Services.
      As part of our policy to provide you with privacy, we also provide you the
      option of opting out from receiving newsletters from us. However, you will
      not be able to opt-out from receiving service announcements and
      administrative messages. Complaints If we receive a complaint from any
      person against you with respect to your activities as part of use of the
      Services, we will forward the complaint to the primary email address of
      your user account. You must respond to the complainant directly within 10
      days of receiving the complaint forwarded by us and copy Labops in the
      communication. If you do not respond to the complainant within 10 days
      from the date of our email to you, we may disclose your name and contact
      information to the complainant for enabling the complainant to take legal
      action against you. You understand that your failure to respond to the
      forwarded complaint within the 10 days’ time limit will be construed as
      your consent to disclosure of your name and contact information by Labops
      to the complainant. Inactive User Accounts Policy We reserve the right to
      terminate unpaid user accounts that are inactive for a continuous period
      of 120 days. In the event of such termination, all data associated with
      such user account will be deleted. We will provide you prior notice of
      such termination and option to back up your data. The data deletion policy
      may be implemented with respect to any or all of the Services. Each
      Service will be considered an independent and separate service for the
      purpose of calculating the period of inactivity. In other words, activity
      in one of the Services is not sufficient to keep your user account in
      another Service active. In case of accounts with more than one user, if at
      least one of the users is active, the account will not be considered
      inactive. Data Ownership We respect your right to ownership of content
      created or stored by you. You own the content created or stored by you.
      Unless specifically permitted by you, your use of the Services does not
      grant Labops the license to use, reproduce, adapt, modify, publish, or
      distribute the content created by you or stored in your user account for
      Labops’ commercial, marketing, or any similar purpose. But you grant
      Labops permission to access, copy, distribute, store, transmit, reformat,
      publicly display and publicly perform the content of your user account
      solely as required for the purpose of providing the Services to you. User
      Generated Content You may transmit or publish content created by you using
      any of the Services or otherwise. However, you shall be solely responsible
      for such content and the consequences of its transmission or publication.
      Any content made public will be publicly accessible through the internet
      and may be crawled and indexed by search engines. You are responsible for
      ensuring that you do not accidentally make any private content publicly
      available. Any content that you may receive from other users of the
      Services is provided to you AS IS for your information and personal use
      only and you agree not to use, copy, reproduce, distribute, transmit,
      broadcast, display, sell, license, or otherwise exploit such content for
      any purpose, without the written consent of the person who owns the rights
      to such content. In the course of using any of the Services, if you come
      across any content with copyright notification, you agree not to remove
      such copyright notification without obtaining the consent of the owner of
      the content. Sample Files and Applications Labops may provide sample files
      and applications for the purpose of demonstrating the possibility of using
      the Services effectively for specific purposes. The information contained
      in any such sample files and applications consists of random data. Labops
      makes no warranty, either express or implied, as to the accuracy,
      usefulness, completeness, or reliability of the information or the sample
      files and applications. Disclaimer of Warranties YOU EXPRESSLY UNDERSTAND
      AND AGREE THAT THE USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES
      ARE PROVIDED ON AN AS-IS-AND-AS-AVAILABLE BASIS. LABOPS EXPRESSLY
      DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
      INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY
      AND FITNESS FOR A PARTICULAR PURPOSE. Modification of Terms of Service
      Labops reserves the right to modify the Terms of Service at any time. Any
      modifications will be effective immediately upon posting the revised terms
      on the Labops website or through other communications. You are responsible
      for reviewing these Terms of Service periodically to stay informed of any
      changes. Your continued use of the Services after the effective date of
      any modifications to these Terms of Service will be deemed acceptance of
      the modified terms. In the event of significant changes, we will notify
      you via email to the primary email address associated with your account or
      by posting a prominent notice on our website. If you do not agree to the
      modified terms, you must discontinue your use of the Services immediately.
      Contact Information: Labops Meditechnology Purba Bardhaman, West Bengal
      Phone: 90028 41677 Email: contact@labops.in
    </div>
  );
};

export default TermsConditionsComp;
