import React from "react";
import Navbar from "../Components/Navbar";
import PrivacyPolicyComp from "../Components/PrivacyPolicyComp";

const PrivacyPolicy = () => {
  return (
    <>
      <PrivacyPolicyComp />
    </>
  );
};

export default PrivacyPolicy;
