import React, { useEffect } from "react";
import AboutComp from "../Components/AboutComp";
import { usePage } from "../Context/PageContext";

function About() {
  const { currentPage, setCurrentPage } = usePage();

  useEffect(() => {
    setCurrentPage("/about");
  }, []);
  return <AboutComp />;
}

export default About;
