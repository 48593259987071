import React, { useEffect } from "react";
import SecurityComp from "../Components/SecurityComp";
import { usePage } from "../Context/PageContext";

function Security() {
  const { currentPage, setCurrentPage } = usePage();

  useEffect(() => {
    setCurrentPage("/security");
  }, []);
  return <SecurityComp />;
}

export default Security;
