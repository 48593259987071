import React from "react";

const PrivacyPolicyComp = () => {
  return (
    <div className="min-h-[93vh] p-5">
      Privacy Policy Introduction Labops Meditechnology Pvt. Ltd. ("Labops,"
      "we," "our," or "us") is committed to the principle of protecting the
      privacy of our users. This Privacy Policy is crafted to delineate the
      parameters within which we collect, process, utilize, and secure personal
      data when our services are engaged. By accessing or using Labops'
      services, you hereby acknowledge and consent to the provisions of this
      Privacy Policy, as may be amended from time to time. Information We
      Collect Personal Information In the course of engaging with our services,
      you may be required to furnish personal information, which may include,
      but is not limited to: ● Full Name ● Email Address ● Phone Number ●
      Physical Address ● Job Title and Company ● Payment or Credit Card
      Information ● Billing Address Usage Data We systematically collect and
      analyze data related to the usage of our services, including, without
      limitation: ● IP Address ● Browser Type ● Device Information ● Pages
      Visited and Actions Undertaken on Our Website or Application ● Date and
      Time of Access ● Referral URLs ● Geolocation Data (where applicable)
      Cookies and Similar Technologies We may deploy cookies and analogous
      technologies to monitor your interactions with our digital properties.
      These technologies are employed to enhance user experience, analyze user
      behavior, and optimize service delivery. Users are advised to consult our
      Cookies Policy for further elucidation. How We Use Your Information The
      utilization of your personal information is circumscribed by, but not
      limited to, the following objectives: ● Provision and maintenance of our
      services ● Processing of transactions, account management, and billing
      facilitation ● Communication of updates, promotional content, and other
      pertinent information ● Enhancement of service offerings and
      personalization based on user behavior ● Training of our AI models to
      improve and refine service delivery and customer experience ●
      Investigation and prevention of fraud, security incidents, and other
      unauthorized activities ● Compliance with legal mandates and the
      enforcement of our terms, policies, and legal rights Legal Basis for
      Processing Our processing activities are underpinned by one or more of the
      following legal justifications: ● Contractual Necessity: The necessity to
      process your data for the performance of a contract to which you are a
      party or in order to take steps at your request prior to entering into a
      contract. ● Legitimate Interests: Processing is necessary for the purposes
      of the legitimate interests pursued by Labops, provided such interests are
      not overridden by your fundamental rights and freedoms. ● Legal
      Obligations: Compliance with legal and regulatory obligations to which we
      are subject. ● Consent: Where you have explicitly consented to specific
      processing activities, such consent shall serve as a legal basis for such
      processing. Sharing Your Information Labops adheres to a policy of
      non-disclosure with respect to personal information, barring certain
      delineated exceptions, including but not limited to: ● Service Providers:
      Third-party service providers engaged by Labops for the performance of
      functions on our behalf, including payment processing, data storage, and
      customer support, who are contractually bound to maintain the
      confidentiality and security of your personal information. ● Legal
      Compliance: Disclosure of personal information as may be required by law
      or in response to valid legal processes, including but not limited to
      court orders, subpoenas, or governmental regulations. ● Business
      Transfers: In the event of a merger, acquisition, restructuring, or sale
      of Labops, personal information may be transferred to the successor
      entity, subject to the terms of this Privacy Policy. International
      Transfers Labops may transfer, store, and process your personal
      information in jurisdictions outside your country of residence. Such
      transfers will be conducted in accordance with applicable legal
      frameworks, ensuring that adequate safeguards are in place to protect your
      personal information. Your Rights Subject to applicable law, you may
      exercise the following rights in relation to your personal information: ●
      Right to Access: The right to obtain confirmation as to whether or not
      personal data concerning you is being processed, and, where that is the
      case, access to the personal data. ● Right to Rectification: The right to
      have inaccurate personal data rectified, or completed if it is incomplete.
      ● Right to Erasure: The right to request the deletion of personal data in
      certain circumstances, such as where it is no longer necessary in relation
      to the purposes for which it was collected. ● Right to Restriction of
      Processing: The right to request the restriction of processing where
      certain conditions apply. ● Right to Data Portability: The right to
      receive personal data in a structured, commonly used, and machine-readable
      format and the right to transmit that data to another controller. ● Right
      to Object: The right to object to processing of personal data for direct
      marketing purposes or where processing is based on legitimate interests. ●
      Right to Withdraw Consent: The right to withdraw consent at any time,
      where processing is based on consent, without affecting the lawfulness of
      processing based on consent before its withdrawal. ● Right to Complain:
      Should you have concerns regarding our data processing practices, you may
      lodge a complaint with the appropriate supervisory authority. Data
      Retention Your personal information will be retained by Labops for the
      duration necessary to fulfill the purposes outlined in this Privacy
      Policy, and as required by applicable law. Upon the cessation of
      necessity, such data will be securely disposed of or anonymized. Security
      Labops implements appropriate technical and organizational measures to
      safeguard personal information from unauthorized access, disclosure,
      alteration, and destruction. Despite these measures, no system of data
      transmission or storage is entirely secure, and we cannot guarantee
      absolute security. Business Transfer Clause In the eventuality of a
      corporate transaction, such as a merger, acquisition, or sale of Labops’
      assets, your personal information may be transferred to the successor
      entity, which will be bound by the terms of this Privacy Policy or a
      substantially similar privacy framework. Changes to This Privacy Policy
      Labops reserves the right to modify or amend this Privacy Policy at its
      sole discretion. Notification of any material changes will be communicated
      through the posting of the revised Privacy Policy on our website, with an
      update to the "Effective Date." Continued use of our services following
      such modifications constitutes acceptance of the revised terms. Compliance
      with Regulations Labops operates in accordance with the data protection
      laws of India, as well as any other jurisdictions where we conduct
      business. We are committed to ensuring that our practices align with
      applicable legal standards, including but not limited to the Information
      Technology (Reasonable Security Practices and Procedures and Sensitive
      Personal Data or Information) Rules, 2011, and other relevant
      regulations.Labops affirms its commitment to compliance with applicable
      data protection laws, including but not limited to the General Data
      Protection Regulation (GDPR) for European Union users, the California
      Consumer Privacy Act (CCPA) for California residents, and other relevant
      regulatory frameworks. We ensure that all processing activities are
      conducted in strict accordance with these legal requirements. Contact Us
      For any inquiries, concerns, or requests related to this Privacy Policy or
      our data handling practices, please contact: Labops Privacy Team Email:
      privacy@labops.in Address: Purba Bardhaman, West Bengal, India
    </div>
  );
};

export default PrivacyPolicyComp;
