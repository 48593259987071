import React from "react";
import { motion } from "framer-motion";

import { cn } from "../../lib/utils";

const animationProps = {
  initial: { "--x": "100%", scale: 0.8 },
  animate: { "--x": "-100%", scale: 1 },
  whileTap: { scale: 0.95 },
  transition: {
    repeat: Infinity,
    repeatType: "loop",
    repeatDelay: 1,
    type: "spring",
    stiffness: 20,
    damping: 15,
    mass: 2,
    scale: {
      type: "spring",
      stiffness: 200,
      damping: 5,
      mass: 0.5,
    },
  },
};

const ShinyButton = ({ children, className, ...props }) => {
  return (
    <motion.button
      {...animationProps}
      {...props}
      className={cn(
        "relative rounded-lg px-6 py-3.5 font-medium backdrop-blur-xl transition-shadow duration-300 ease-in-out hover:shadow",
        className
      )}
      style={{
        position: "relative",

        backgroundColor: "#1A73E8", // Set background color to #1A73E8
        color: "#fff", // Optional: Set text color to white for contrast
      }}
    >
      <span
        className="relative block size-full text-[15px] uppercase tracking-wide text-[rgb(255,255,255,90%)] dark:font-light"
        style={{
          maskImage:
            "linear-gradient(-75deg, #1A73E8 calc(var(--x) + 20%), transparent calc(var(--x) + 30%), #1A73E8 calc(var(--x) + 100%))",
        }}
      >
        {children}
      </span>
    </motion.button>
  );
};

export default ShinyButton;
