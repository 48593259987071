import React, { useEffect, useState } from "react";
import mobileImg1 from "../Assets/Home/002 1.png";
import img1 from "../Assets/Home/Group 107.png";
import img2 from "../Assets/Home/Group 393.png";
import img3 from "../Assets/Home/mob.png";
import img4 from "../Assets/Home/BG.png";
import img5 from "../Assets/Home/Frame 1000006567.png";
import img6 from "../Assets/Home/image.png";
import img7 from "../Assets/Home/Rectangle 158.png";
import img8 from "../Assets/Home/Rectangle 160.png";
import img9 from "../Assets/Home/009 1.png";
import video from "../Assets/Home/video.mp4";
import video1 from "../Assets/Home/hero1.mp4";
import img10 from "../Assets/Home/image1.png";
import img11 from "../Assets/Home/Banner.png";
import img12 from "../Assets/Home/girlmg.webp";
import img13 from "../Assets/Home/img2.png";
import logo from "../Assets/logo.png";
import {
  Activity,
  Award,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Cpu,
  Lock,
  Phone,
  PhoneCall,
} from "react-feather";
import { useNavigate } from "react-router-dom";
import ShinyButton from "./magicui/ShinyButton";
import { AnimatedGradientText } from "./magicui/AnimatedGradientText";
import { cn } from "../lib/utils";
import axios from "axios";

function HomeComp() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [expanded, setExpanded] = useState({});
  const navigate = useNavigate();
  const toggleReadMore = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const getAllBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/blogs/`
      );
      console.log(response?.data);
      setBlogs(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Options for displaying date as "30 Jan 2024"
    const options = { day: "numeric", month: "short", year: "numeric" };

    // Format the date using toLocaleDateString with English locale
    return date.toLocaleDateString("en-GB", options);
  };

  useEffect(() => {
    getAllBlogs();
  }, []);
  const accordionData = [
    {
      question: "Is Labops suitable for both pathology and diagnostic labs?",
      answer:
        "Yes, Labops is designed to cater to the needs of both pathology and diagnostic labs, making it a versatile solution for various laboratory settings.",
    },
    {
      question: "How secure is Labops for health data?",
      answer:
        "Labops prioritizes your privacy and security. With end-to-end encryption, HIPAA compliance, and regular audits, your health data is safeguarded with the highest standards.",
    },
    {
      question: "Can I access Labops from mobile devices?",
      answer:
        "Yes, Labops is accessible from both desktop and mobile devices, ensuring you can manage your lab anytime, anywhere.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
  ];
  return (
    <>
      <div
        className="relative w-full flex justify-center h-[32rem] lg:h-[92.5vh] bg-cover bg-center"
        style={{ backgroundImage: `url(${img11})` }}
      >
        {/* Flex container for two components */}
        <div className="flex max-w-[81rem] justify-between w-full h-full relative">
          <div className="flex flex-col p-4 justify-center max-w-[40rem]">
            <div className=" left-0 relative">
              <AnimatedGradientText>
                <img src={logo} className="w-6 pr-1" alt="" />{" "}
                <span
                  className={cn(
                    `inline animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`
                  )}
                >
                  Introducing Labops 2.0
                </span>
                <ChevronRight className="ml-1 size-4 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
              </AnimatedGradientText>
            </div>

            <span className="md:text-[4rem] pt-[1rem] text-[2.5rem] text  uppercase font-bold">
              Making health{" "}
              <span className="text-blue-600 block mt-[-1rem] lg:mt-[-1.5rem]">
                connected
              </span>
            </span>
            <span className="lg:text-[1.3rem] text-[1rem] block mt-[1rem]">
              Labops revolutionizes diagnostic healthcare by seamlessly
              connecting labs and patients, leveraging technology to drive
              efficiency, affordability.
            </span>

            {/* <div className="flex justify-start">
              <ShinyButton>
                <span className="flex items-center text-blue-500">
                  <PhoneCall className="mr-2 w-5 h-5 text-blue-500" />{" "}
                
                  <span className="text-[16px] text-blue-500">
                    +91 900284167
                  </span>
                </span>
              </ShinyButton>
            </div> */}
          </div>
          {/* Right component */}
          <div className="absolute hidden z-1  lg:block max-w-[47rem] right-0 bottom-[-5rem]">
            <img
              src={img12}
              className="max-w-[38rem] "
              alt="Image description"
            />
          </div>
        </div>
      </div>

      <div className="w-full relative z-30 flex flex-col text-[#3B3B3B] items-center bg-white p-4 md:p-8 lg:p-8">
        <div className="w-full  max-w-[81rem] grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2">
          <div className="bg-[#F6EBD3] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center mb-4">
              <Award size={30} />
            </div>
            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Reliable
            </span>
            <p className="text-base text-start">
              Ensuring consistent performance, Labops enhances operational
              reliability for labs, enabling seamless coordination with patients
              while maintaining precision and trust throughout the diagnostic
              journey.
            </p>
          </div>

          <div className="bg-[#E1E3D4] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center mb-4">
              <Activity size={30} />
            </div>
            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Fast
            </span>
            <p className="text-base text-start">
              Accelerating healthcare interactions, Labops speeds up lab
              processes and patient bookings, driving efficiency without
              compromising quality, so labs and patients can access care faster
              than ever
            </p>
          </div>

          <div className="bg-[#B8DDEC] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center mb-4">
              <Lock size={30} />
            </div>
            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Secure
            </span>
            <p className="text-base text-start">
              Labops is a comprehensive software solution designed to streamline
              Built with top-tier data protection, Labops safeguards sensitive
              health information, providing labs and patients peace of mind with
              uncompromised security at every step of the process
            </p>
          </div>

          <div className="bg-[#D8C4D4] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center mb-4">
              <Cpu size={30} />
            </div>
            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Advanced
            </span>
            <p className="text-base text-start text-[#6B6B6B]">
              Powered by AI and modern technology, Labops delivers advanced
              solutions that elevate lab management and patient care, setting a
              new standard for innovation in healthcare services.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center bg-white py-5 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full max-w-[81rem] flex flex-col gap-5">
          {/* <span className="text-lg font-semibold">Features</span> */}
          <span className="md:text-4xl text-3xl font-medium">
            Powerful Features
          </span>
          <div className="w-full flex flex-col xl:flex-row gap-5">
            <div className="flex flex-col md:flex-row  xl:flex-col gap-5">
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">Test Booking</span>
                <span className="text-[#4E4E4E]">
                  Book diagnostic tests effortlessly with the Labops app,
                  offering quick access to labs, easy scheduling, and seamless
                  integration for a smoother healthcare experience.
                </span>
              </div>
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">
                  AI Health Monitoring
                </span>
                <span className="text-[#4E4E4E]">
                  Monitor your health with AI-powered insights, helping you
                  track vital trends and receive personalized health guidance
                  for proactive care, all within the Labops app.
                </span>
              </div>
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">History Management</span>
                <span className="text-[#4E4E4E]">
                  Keep all your medical reports and health records organized in
                  one place, with easy access to your test history for better
                  health tracking and management
                </span>
              </div>
            </div>
            <div className="lg:flex-1 h-[30rem] lg:h-auto flex justify-center items-end bg-[#B9E97D] rounded-lg ">
              <img
                className="w-full max-w-[18rem]  md:max-w-[20rem] h-auto"
                src={img3}
                alt="Feature illustration"
              />
            </div>
            <div className="flex flex-col md:flex-row  xl:flex-col gap-5">
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">More Affordable</span>
                <span className="text-[#4E4E4E]">
                  Enjoy cost-effective healthcare with Labops, offering
                  discounted test rates and affordable diagnostic solutions to
                  make quality medical services accessible to everyone.
                </span>
              </div>
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">Best Service</span>
                <span className="text-[#4E4E4E]">
                  Experience top-notch healthcare services with Labops,
                  combining convenience, efficiency, and personalized care to
                  ensure the best diagnostic experience for every patient.
                </span>
              </div>
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">Safe</span>
                <span className="text-[#4E4E4E]">
                  Labops guarantees the highest level of data security, keeping
                  your health information safe and secure while ensuring privacy
                  in all your healthcare interactions.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center bg-[#F2F4EF] py-16 px-4 md:px-8 lg:px-12 text-[#3B3B3B]">
        <div className="w-full max-w-[81rem] flex flex-col gap-8">
          {/* Heading */}
          <span className="md:text-4xl text-3xl font-semibold text-center md:text-left">
            Need to run your diagnostic
          </span>

          {/* Description */}
          <p className="max-w-[50rem] text-lg leading-relaxed text-center md:text-left">
            Yes, Labops is designed to cater to the needs of both pathology and
            diagnostic labs, making it a versatile solution for various
            laboratory settings.
          </p>

          {/* Video and Image Section */}
          <div className="w-full flex gap-y-2 relative flex-col md:flex-row justify-center items-center bg-[#F2F4EF] gap-0">
            {/* Video Section */}
            <div className="w-[90%]  md:w-[72rem] rounded-lg relative flex justify-center items-center">
              <video
                autoPlay
                loop
                muted
                playsInline
                className="w-full rounded-md"
              >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* <img src={img13} alt="" /> */}
            </div>

            {/* Image Section */}
            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img className="w-full max-w-[35rem]" src={img10} alt="Labops" />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full flex flex-col items-center bg-white py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full h-full  max-w-[81rem] flex flex-col lg:flex-row gap-10 items-center">
          {/* Left Image Section */}
          <div className="flex-1 ">
            <img
              src={img6}
              className="w-full max-h-[45rem] rounded-xl object-cover"
              alt=""
            />
          </div>

          {/* Right Content Section */}
          <div className="flex-1 flex flex-col justify-between gap-y-5">
            {/* Title and Description */}
            <div className="w-full flex flex-col gap-4">
              <span className="lg:text-3xl text-[1.8rem]  font-semibold text-[#3B3B3B] ">
                Your Health Data, Safeguarded with the Highest Standards
              </span>
              <span className="text-[#6B6B6B]  h-[11rem] md:h-[8rem] leading-relaxed">
                At Labops, your privacy is our priority. We use advanced
                security to protect your health data, from test booking to
                report access. With top encryption, secure cloud storage, and
                global compliance, we ensure your medical information stays safe
                and private.
              </span>
            </div>

            {/* Bullet Points Section */}
            <div className="w-full flex flex-col gap-y-6 lg:pl-5">
              {/* Item 1 */}
              <div className="w-full flex flex-col border-b-2 pb-5 border-b-[#F0F1F3]">
                <span className="text-[1.2rem] font-medium">
                  1. Data Encryption
                </span>
                <span className="text-[#6B6B6B]">
                  Your health data is fully encrypted during transmission and
                  storage, preventing unauthorized access and ensuring secure
                  data handling.
                </span>
              </div>

              {/* Item 2 */}
              <div className="w-full flex flex-col border-b-2 pb-5 border-b-[#F0F1F3]">
                <span className="text-[1.2rem] font-medium">
                  2. Secure Cloud-Based Storage
                </span>
                <span className="text-[#6B6B6B]">
                  We use highly secure cloud storage solutions to protect your
                  medical records from cyber threats, giving you safe and easy
                  access to your health information.
                </span>
              </div>

              {/* Item 3 */}
              <div className="w-full flex flex-col border-b-2 pb-5 border-b-[#F0F1F3]">
                <span className="text-[1.2rem] font-medium">
                  3. Multi-Factor Authentication (MFA)
                </span>
                <span className="text-[#6B6B6B]">
                  Labops strengthens account security with MFA, ensuring that
                  only authorized users can access sensitive health information
                </span>
              </div>

              {/* Item 4 */}
              <div className="w-full flex flex-col border-b-2 pb-5 border-b-[#F0F1F3]">
                <span className="text-[1.2rem] font-medium">
                  4. Compliance with Global Privacy Standards
                </span>
                <span className="text-[#6B6B6B]">
                  Labops meets healthcare regulations like HIPAA and GDPR,
                  providing top-level compliance and security for your health
                  data across all platforms.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-full flex flex-col items-center bg-[#F2F4EF] py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full flex max-w-[81rem] flex-col lg:flex-row gap-y-5 gap-x-10 items-start">
          {/* Left Text Section */}
          <div className="lg:flex-1 w-full">
            <div className="w-full flex flex-col gap-y-3">
              <span className="text-[#3B3B3B] text-3xl md:text-4xl font-bold">
                Frequently Asked Questions
              </span>
              <span className="text-[#6B6B6B] text-lg">
                Get answers to the most commonly asked questions about Labops.
              </span>
            </div>

            {/* Accordion */}
            <div className="mt-8 flex flex-col gap-y-2">
              {accordionData.map((item, index) => (
                <div
                  key={index}
                  className={`border-b-2 border-[#E0E0E0] rounded-xl py-4 transition-all duration-500 ease-in-out ${
                    activeIndex === index
                      ? "bg-white shadow-lg rounded-md"
                      : "bg-white"
                  }`}
                >
                  <div
                    className="flex justify-between items-center px-5 py-2 cursor-pointer transition-transform transform"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="text-[#3B3B3B] text-lg font-medium w-[90%]">
                      {item.question}
                    </span>
                    <span className="text-[#6B6B6B] bg-[#D9D9D9] flex justify-center items-center h-[1.6rem] w-[1.6rem] rounded-lg text-xl transition-transform duration-500 ease-in-out">
                      {activeIndex === index ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </span>
                  </div>

                  {/* Accordion content with smoother transition */}
                  <div
                    className={`overflow-hidden px-5 transition-all duration-700 ease-in-out ${
                      activeIndex === index
                        ? "max-h-[1000px] opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                    style={{
                      transition:
                        "max-height 0.7s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.7s ease-in-out",
                    }}
                  >
                    <div className="mt-4 text-[#6B6B6B] text-base">
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Image Section */}
          <div className="md:flex-1 w-full lg:max-w-[35%]">
            <img
              src={img7}
              className="rounded-lg w-full object-cover shadow-md"
              alt="FAQ Image"
            />
          </div>
        </div>
      </div>

      <div className="w-full h-full flex flex-col items-center bg-white py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full flex  max-w-[81rem] flex-col  gap-y-5 gap-x-10 items-start">
          {/* Left Text Section */}
          <div className="lg:flex-1 w-full">
            <div className="w-full flex flex-col gap-y-3">
              <span className="text-[#3B3B3B] text-3xl md:text-4xl font-bold">
                Insights don’t miss to read
              </span>
              <span className="text-[#6B6B6B] text-lg">
                Explore a wide range of informative articles to stay updated on
                important health topics
              </span>
            </div>
          </div>
          {/* Right Image Section */}
          <div className="w-full h-full flex flex-col items-center bg-white py-16 px-0 md:px-8 lg:px-0 text-[#3B3B3B]">
            <div className="w-full max-w-[81rem] grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-12">
              {blogs?.map((blog, index) => {
                const isExpanded = expanded[index];
                const description = blog?.short_description;

                return (
                  <div
                    key={index}
                    className="bg-[#F9F9F9] h-auto p-6 rounded-lg shadow-lg flex flex-col gap-4"
                  >
                    <img
                      onClick={() => navigate(`/blog-detail/${blog?.slug}`)}
                      src={`${blog?.image}`}
                      alt="Article Image"
                      className="w-full h-[15rem] cursor-pointer rounded-lg object-cover"
                    />
                    {/* <img
                      onClick={() => navigate(`/blog-detail/${blog?.slug}`)}
                      src={`${process.env.REACT_APP_BASEURL}/${blog?.image}`}
                      alt="Article Image"
                      className="w-full h-[15rem] cursor-pointer rounded-lg object-cover"
                    /> */}

                    <div className="flex items-center gap-x-4 text-[#6B6B6B] text-sm">
                      <span>{formatDate(blog?.publish)}</span>
                      {/* <span className="block bg-[#6B6B6B] h-2 w-2 rounded-full"></span>
                      <span>12 mins read</span> */}
                    </div>
                    <h3 className="text-xl font-semibold text-[#3B3B3B]">
                      {blog?.title}
                    </h3>
                    <div className="relative">
                      <p
                        className={`text-[#3B3B3B] overflow-hidden ${
                          isExpanded ? "max-h-full" : "max-h-[4.5rem]"
                        }`}
                      >
                        {description}
                      </p>
                      {description.length > 100 && (
                        <button
                          className="text-[#488FED] font-semibold mt-2"
                          onClick={() => toggleReadMore(index)}
                        >
                          {isExpanded ? "Read Less" : "Read More"}
                        </button>
                      )}
                    </div>
                    <div className="flex items-center gap-3">
                      <img
                        className="w-12 h-12 rounded-full object-cover"
                        src={logo}
                        alt="Author"
                      />
                      <span className="text-[#3B3B3B] font-[600] text-[1.1rem]">
                        Labops
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            {blogs?.length > 4 && (
              <div className="w-[9rem] cursor-pointer transition-all hover:border-[#488FED] ease-in-out duration-300 hover:bg-[#488FED] hover:text-white flex justify-center border-[#488FED] border-[1px] text-[#488FED] rounded-full h-[3rem] md:h-[3.5rem] mt-5">
                <button>See All</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bg-[#F3EFE7] hidden lg:flex justify-center items-center text-[#3B3B3B] h-[6rem] w-full">
        {" "}
        <h2 className="lg:text-6xl text-4xl leading-[3rem] font-bold lg:leading-[4.5rem]"></h2>
      </div>

      <div className="w-full h-full relative flex flex-col items-center bg-white py-0 px-4 md:px-8 lg:px-6 text-[#3B3B3B]">
        <div className="w-full  max-w-[81rem] flex flex-col gap-y-10 lg:flex-row items-center lg:gap-x-10">
          {/* Text Section */}
          <div className="flex-1 flex flex-col items-center lg:items-start gap-y-5 text-center lg:text-left">
            <h2 className="lg:text-6xl text-4xl leading-[3rem] font-bold lg:leading-[4.5rem]">
              We're Ready to Help, Contact Us Anytime!
            </h2>
            <p className="text-[#6B6B6B] text-base lg:text-lg">
              Got questions or need support? We're here to listen and assist you
              with whatever you need.
            </p>
            <div className="flex justify-start">
              <a href="/contact">
                <button className="bg-gradient-to-r from-[#4C8DF5] w-[16rem] mt-[1rem] to-[#1A73E8] text-[16px] font-semibold  max-w-[12rem] h-[3.5rem] rounded-lg flex items-center justify-center text-white shadow-md hover:shadow-lg transition-all duration-300 ease-in-out">
                  <span className="flex items-center">
                    <PhoneCall className="mr-2 w-5 h-5" />{" "}
                    {/* Phone icon with size */}
                    <span className="text-[16px] text-white">
                      Contact us now
                    </span>
                  </span>
                </button>
              </a>
            </div>
          </div>

          {/* Image Section */}
          <div className="flex-1 relative">
            <img src={img9} className="w-full object-cover" alt="Contact Us" />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeComp;
