import React from "react";
import styled from "styled-components";

const ProseContainer = styled.div`
  /* Headings */
  h1 {
    font-weight: 600;
    color: #222;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }
  h2 {
    font-weight: 600;
    color: #222;
    line-height: 1.2;
    margin-bottom: 1.2rem;
  }
  h3 {
    font-weight: 600;
    color: #222;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 600;
    color: #222;
    line-height: 1.2;
    margin-bottom: 0.9rem;
  }
  h5 {
    font-weight: 600;
    color: #222;
    line-height: 1.2;
    margin-bottom: 0.7rem;
  }

  h6 {
    font-weight: 600;
    color: #222;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  /* Custom size for headings */
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }

  /* Paragraphs */
  p {
    margin-bottom: 1rem;
    color: #555;
  }

  /* Links */
  a {
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  /* Lists */
  ul,
  ol {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  /* Tables */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }

  th,
  td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
    font-weight: 600;
  }

  /* Forms */
  input,
  select,
  textarea,
  button {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  button {
    width: auto;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }

  /* Blockquote */
  blockquote {
    margin: 1.5rem 0;
    padding-left: 1rem;
    border-left: 4px solid #ddd;
    color: #555;
  }

  /* Images */
  img {
    max-width: 100%;
    height: auto;
  }

  /* Code & Preformatted Text */
  code {
    font-family: "Courier New", monospace;
    background-color: #f4f4f4;
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
  }

  pre {
    background-color: #f4f4f4;
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
  }

  /* HR (Horizontal Rule) */
  hr {
    border: 0;
    border-top: 1px solid #ddd;
    margin: 2rem 0;
  }

  /* Address */
  address {
    font-style: normal;
    color: #555;
    margin-bottom: 1rem;
  }

  /* Other inline elements */
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  /* Buttons */
  button,
  input[type="submit"],
  input[type="reset"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 4px;
  }

  button:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover {
    background-color: #0056b3;
  }

  /* Forms - focus styles */
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: 2px solid #007bff;
    outline-offset: 2px;
  }

  /* Default style for labels */
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  /* Styles for fieldsets and legends */
  fieldset {
    border: 1px solid #ddd;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  legend {
    font-size: 1.25rem;
    font-weight: bold;
  }

  /* Default iframe styles */
  iframe {
    border: none;
    width: 100%;
    height: auto;
  }

  /* Media Queries for responsive adjustments */
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.75rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
`;

const BlogContent = ({ content }) => {
  return (
    <ProseContainer>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </ProseContainer>
  );
};

export default BlogContent;
